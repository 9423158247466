import React from "react";
import $ from "jquery";
import {
  ApolloLink,
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import _ from "lodash";
import Headers from "fetch-headers/headers-es5.min";
import omitDeep from "omit-deep-lodash";
import { RestLink } from "apollo-link-rest";
import { redToast, yellowToast } from "components/reusable/relevant_toast";

function createClient() {
  global.Headers = global.Headers || Headers;
  const token = $("meta[name=csrf-token]").attr("content");
  const restLink = new RestLink({
    uri: "/api/",
    headers: { "X-CSRF-Token": token },
    fieldNameNormalizer: (key) => _.camelCase(key),
    fieldNameDenormalizer: (key) => _.snakeCase(key),
  });

  // https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-576687277
  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const keysToOmit = ["__typename"]; // more keys like timestamps could be included here

    const def = getMainDefinition(operation.query);
    if (def) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = omitDeep(operation.variables, keysToOmit);
    }

    return forward ? forward(operation) : null;
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        window.console.error(
          `[Apollo GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        redToast(
          "Something went wrong",
          `Our server returned the following error message: ${message}. Contact Relevant Support if the error persists.`
        );
      });
    // These are commonly 401s when the user has been signed out. We capture them
    // as warnings to reduce alert noise in slack.
    if (networkError) {
      window.console.warn(`[Apollo Network error]: ${networkError}`);
      yellowToast();
    }
  });

  const link = ApolloLink.from([
    cleanTypenameLink,
    restLink,
    errorLink,
    new HttpLink({ uri: "/graphql", headers: { "X-CSRF-Token": token } }),
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache({}),
    typeDefs: {},
  });
}

export default function apolloEntryPoint(component) {
  const Component = component;

  return (props) => (
    <ApolloProvider client={createClient()}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...props} />
    </ApolloProvider>
  );
}
